var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "item",
            staticStyle: { flex: "1" },
            attrs: { placeholder: "Search user" },
            model: {
              value: _vm.search.input,
              callback: function ($$v) {
                _vm.$set(_vm.search, "input", $$v)
              },
              expression: "search.input",
            },
          }),
          _c(
            "el-button",
            {
              staticClass: "new-user item",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$router.push("/user/new")
                },
              },
            },
            [_vm._v(" New User ")]
          ),
        ],
        1
      ),
      _c("user-list", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        attrs: {
          "element-loading-text": "Loading users",
          users: _vm.users,
          show_details: !_vm.viewInactive,
        },
        on: {
          "new-claim": _vm.newClaim,
          prev: _vm.getPrevPageOfUsers,
          next: _vm.getNextPageOfUsers,
          open: _vm.handleOpenUser,
        },
      }),
      _c("claim-modal", {
        attrs: {
          categories: _vm.user_categories,
          data: _vm.user_plan,
          user: _vm.user,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }