import axios from 'axios';
import {Message, Notification} from 'element-ui';
import {firebaseApp} from '@/utils/firebase';

// axios
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URI,
    timeout: 30000,
});

service.source = axios.CancelToken.source();
service.isCancel = axios.isCancel;

// request
service.interceptors.request.use(
    (config) => {
        config.headers['Access-Control-Allow-Origin'] = '*';

        if (!config.cancelToken) {
            config.cancelToken = service.source.token;
        }

        const firebaseUser = firebaseApp.auth().currentUser;
        if (firebaseUser) {
            return (async () => {
                const token = await firebaseUser.getIdToken();
                config.headers['Authorization'] = 'Bearer ' + token;
                return config;
            })();
        }

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

// response
service.interceptors.response.use(
    (response) => {
        const res = response;

        if (res.status >= 200 && res.status <= 299) {
            return response.data;
        } else {
            return Promise.reject('error');
        }
    },
    (error) => {
        if (service.isCancel(error)) {
            console.log('Request cancelled: ', error.message);
        } else if (
            error.response &&
            error.response.data &&
            error.response.data.message
        ) {
            let message = error.response.data.message;
            showErrorMessage('Error', message);
            if (error.response.status < 500) return Promise.reject(error);
        } else {
            Message({
                message: error.message,
                type: 'error',
                duration: 5 * 1000,
            });
        }

        return Promise.reject(error);
    }
);

function showErrorMessage(title, message, formatted = false) {
    setTimeout(function () {
        Notification({
            type: 'error',
            title: title,
            message: message,
            dangerouslyUseHTMLString: formatted,
        });
    }, 50);
}

export default service;
