<template>
    <div class="app-container">
        <div class="filter-container">
            <el-input
                v-model="search.input"
                style="flex: 1"
                class="item"
                :placeholder="'Search user'"
            />
            <el-button
                type="primary"
                class="new-user item"
                @click="$router.push('/user/new')"
            >
                New User
            </el-button>
        </div>

        <user-list
            v-loading="loading"
            element-loading-text="Loading users"
            :users="users"
            :show_details="!viewInactive"
            @new-claim="newClaim"
            @prev="getPrevPageOfUsers"
            @next="getNextPageOfUsers"
            @open="handleOpenUser"
        />

        <claim-modal
            :categories="user_categories"
            :data="user_plan"
            :user="user"
        />
    </div>
</template>

<script>
import UserList from '@/views/components/UserList';
import ClaimModal from '@/views/components/ClaimModal';
import _ from 'lodash';
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import firebase from 'firebase';
import request from '@/utils/request';

export default {
    name: 'users',
    components: {
        UserList,
        ClaimModal,
    },
    props: {
        view: {
            type: String,
            default: null,
        },
        initial_search: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            users: [],
            loading: true,
            search: {
                input: '',
                debounced: '',
                results: null,
                cancelToken: null,
            },
            query: {
                page: 1,
                limit: 14,
                orderBy: 'name',
                orderDir: 'asc',
            },
            navigating: false,

            // new claims
            user_categories: [],
            user_plan: [],
            user: {},
            raw_categories: [],
        };
    },
    computed: {
        viewStatus() {
            return this.view === 'inactive' ? 'inactive' : 'active';
        },
        viewInactive() {
            if (this.view === 'inactive') return true;
            return false;
        },
    },
    watch: {
        'search.input'(value, oldValue) {
            // don't search if both old and new value are empty queries
            if (value || oldValue) this.searchUsers(value);
        },
        $route(to) {
            this.getFirstPageOfUsers();
        },
    },
    mounted() {
        this.search.input = this.initial_search;

        // categories for new claim modal
        this.$bind(
            'raw_categories',
            this.$fire.collection('categories').orderBy('order'),
            {maxRefDepth: 0}
        );

        if (!this.initial_search) {
            this.getFirstPageOfUsers();
        }
    },
    methods: {
        getFirstPageOfUsers() {
            if (this.search.debounced) {
                // get users based on Algolia search results
                this.getSearchResults();
            } else {
                // get users directly from Firebase
                this.search.results = null;
                this.getPageOfUsers();
            }
        },
        getPageOfUsers(fromUser, forward) {
            window.history.replaceState({}, 'TP Records', this.$route.path);

            this.loading = true;
            let query = this.$fire
                .collection('users')
                .where('status', '==', this.viewStatus);

            query = query.orderBy(this.query.orderBy, this.query.orderDir);

            if (fromUser && forward) {
                // get page forward from last result
                query = query.startAfter(fromUser).limit(this.query.limit);
            } else if (fromUser) {
                // get page previous to last result
                query = query.endBefore(fromUser).limitToLast(this.query.limit);
            } else {
                query = query.limit(this.query.limit);
            }

            // check that there are results on the page to be displayed;
            // if not, do nothing. (This is a double call, so is a performance hit)
            query.get().then((snapshot) => {
                // selecting a user could interrupt query; don't rebind if navigating
                if (!this.navigating) {
                    if (snapshot.size > 0) {
                        this.$bind('users', query, {maxRefDepth: 0})
                            .then(() => {
                                this.stopLoading();
                            })
                            .catch((e) => {
                                this.users = [];
                                this.stopLoading();
                                Sentry.captureException(e);
                            });
                    } else {
                        this.stopLoading();
                    }
                }
            });
        },
        getNextPageOfUsers() {
            if (this.search.results) {
                // get next page of results from search, if available
                if (
                    this.search.results.page + 1 <
                    this.search.results.nbPages
                ) {
                    this.getSearchResults(this.search.results.page + 1);
                }
            } else {
                // get last user of current page, then requery
                if (this.users?.length) {
                    const lastUser = this.users[this.users.length - 1].id;
                    const userRef = this.$fire
                        .collection('users')
                        .doc(lastUser);
                    userRef.get().then((snapshot) => {
                        this.getPageOfUsers(snapshot, true);
                    });
                }
            }
        },
        getPrevPageOfUsers() {
            if (this.search.results) {
                // get previous page of results from search, if available
                if (this.search.results.page > 0) {
                    this.getSearchResults(this.search.results.page - 1);
                }
            } else {
                // get first user of current page, then requery
                if (this.users?.length) {
                    const firstUser = this.users[0].id;
                    const userRef = this.$fire
                        .collection('users')
                        .doc(firstUser);
                    userRef.get().then((snapshot) => {
                        this.getPageOfUsers(snapshot, false);
                    });
                }
            }
        },

        searchUsers: _.debounce(function (value) {
            this.search.debounced = value;
            this.getFirstPageOfUsers();
        }, 500),

        async getSearchResults(page = 0) {
            this.loading = true;

            if (this.search.cancelToken) {
                this.search.cancelToken.cancel('new search performed');
            }

            try {
                const CancelToken = axios.CancelToken;
                this.search.cancelToken = CancelToken.source();

                const result = await request({
                    url: `${process.env.VUE_APP_BASE_URI}/search?query=${this.search.debounced}&page=${page}&inactive=${this.viewInactive}`,
                    method: 'get',
                    cancelToken: this.search.cancelToken.token,
                });

                this.search.cancelToken = null;
                this.search.results = result?.data ?? {
                    hits: [],
                    nbHits: 0,
                    page: 0,
                    nbPages: 0,
                };

                await this.getPageOfUsersFromSearch();
            } catch (e) {
                if (!axios.isCancel(e)) {
                    this.$notify.error({
                        title: 'Error performing search',
                        message: e.response?.data?.data?.message ?? e.message,
                    });
                    this.search.results = null;
                    this.loading = false;
                }
            }

            // update query in URL if required
            const query = this.search.debounced
                ? '?q=' + encodeURIComponent(this.search.debounced)
                : '';
            if (query !== location.search) {
                window.history.replaceState(
                    {},
                    'TP Records',
                    this.$route.path + query
                );
            }
        },

        async getPageOfUsersFromSearch() {
            if (!this.search.results || !this.search.results.hits) {
                this.users = [];
                this.stopLoading();
                return;
            }

            if (this.search.results?.nbHits > 0) {
                let hits = this.search.results.hits;
                // find any exact matches on ndis number or mobile for the search string
                const exact = [];
                for (const hit of hits) {
                    if (
                        hit.ndis_number === this.search.debounced ||
                        hit.mobile === this.search.debounced
                    ) {
                        exact.push(hit);
                    }
                }
                // if exact matches were found, use only those results instead
                if (exact.length) {
                    hits = exact;
                }

                const ids = hits.map((user) => user.objectID);
                try {
                    let query = this.$fire
                        .collection('users')
                        .where(
                            firebase.firestore.FieldPath.documentId(),
                            'in',
                            ids
                        );
                    // can't bind query here because we want the order to match the search results,
                    // so do an explicit query and re-sort to match
                    // (remapping plan references to strings to match bind behaviour)
                    const userResult = await query.get();
                    this.users = userResult.docs
                        .map((u) => ({
                            ...u.data(),
                            id: u.id,
                            plans: u.get('plans')?.map((plan) => plan.path),
                        }))
                        .sort((a, b) => {
                            const index_a = this.search.results.hits.findIndex(
                                (hit) => hit.objectID === a.id
                            );
                            const index_b = this.search.results.hits.findIndex(
                                (hit) => hit.objectID === b.id
                            );
                            return index_a - index_b;
                        });
                } catch (e) {
                    Sentry.captureException(e);
                } finally {
                    this.stopLoading();
                }
            } else {
                this.users = [];
                this.stopLoading();
            }
        },

        newClaim(user, activePlan) {
            this.user = user;
            this.user_plan = activePlan.data.map((item) => {
                return {category: item.category.path, budget: item.budget.path};
            });

            // get assigned categories for user
            const plan_categories = activePlan.data.map((item) => {
                return item.category.path;
            });
            this.user_categories = this.raw_categories
                .filter((raw_cat) => {
                    return plan_categories.includes(`categories/${raw_cat.id}`);
                })
                .sort((a, b) => {
                    return a.order - b.order;
                });
            this.$bus.$emit('claim-modal', {user});
        },

        handleOpenUser() {
            // navigating to a user record, block rebinding in case a query is in progress
            this.navigating = true;
        },

        stopLoading() {
            // hide loading spinner only if there isn't an active search
            if (!this.search.cancelToken) {
                this.loading = false;
            }
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.pagination-container {
    background-color: white;
    margin: 0;
    padding: 20px;
    border: 1px solid #ebeef5;
    border-top: 0;
    display: flex;
    justify-content: center;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.filter-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    div {
        margin-right: 10px;
    }

    .new-user {
        float: right;
    }
}

.full-width {
    width: 100%;
}

.el-tag {
    width: 100%;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 13px;
    font-weight: 500;
}
</style>
